import React from 'react'
import Section from '../components/Section'
import Helmet from 'react-helmet'
import Navigation from '../components/Navigation'
import Layout from '../components/Layout'

export default () => (
  <React.Fragment>
    <Layout footerless>
      <Navigation />
      <Section fullheight>
        <h1 className='title is-3' style={{ margin: 15 }}>
          404
          <span className='w300' style={{ borderLeft: '2px solid #00000025', paddingLeft: 10, marginLeft: 5 }}>
            Page not found
          </span>
        </h1>
      </Section>
    </Layout>
    <Helmet>
      <title>404 - Page not found | Johannes Ekman</title>
    </Helmet>
  </React.Fragment>
)
